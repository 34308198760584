<template>
  <div>
    <p>Gallery area</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
